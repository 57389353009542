













































































































































import Vue from 'vue';
import { mdiOpenInNew } from '@mdi/js';
export default Vue.extend({
  name: 'About',
  data() {
    return {
      mdiOpenInNew
    }
  }
});

